angular.module('app.controllers').controller('BannerGeneratorCtrl', ['$scope', '$sce', '$timeout', '$http'].append(function(self, $sce, $timeout, $http) {
  return self.generateBanner = function() {
    var bannerSize, booth, config, hall, hallSelect, imgElement, size, standInput;
    // Get values from the form
    hallSelect = angular.element('#nav-tabBanner_a .tab-pane.active .halle');
    standInput = angular.element('#nav-tabBanner_a .tab-pane.active .standnummer');
    size = angular.element('#nav-tabBanner_a .tab-pane.active .size');
    imgElement = angular.element('#nav-tabBanner_a .tab-pane.active .banner_image');
    hall = hallSelect.val();
    booth = standInput.val();
    bannerSize = size.val();
    if (!hall || !booth) {
      return;
    }
    
    // Build the config for banner.php
    config = {
      hall: hall,
      booth: booth,
      size: bannerSize
    };
    
    // Send request to banner.php
    return $http.post('core/banner.php', JSON.stringify(config)).then(function(response) {
      if (response.data.success) {
        return imgElement.prop('src', response.data.base64);
      } else {
        return alert('Fehler beim Generieren des Banners.');
      }
    }).catch(function(error) {
      console.error('Error generating banner:', error);
      return alert('Fehler beim Generieren des Banners.');
    });
  };
}));
