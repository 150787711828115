angular.module('app.controllers').controller 'BannerGeneratorCtrl', [
  '$scope', '$sce', '$timeout', '$http'
].append (self, $sce, $timeout, $http) ->
  
  self.generateBanner = ->
    # Get values from the form
    hallSelect = angular.element('#nav-tabBanner_a .tab-pane.active .halle')
    standInput = angular.element('#nav-tabBanner_a .tab-pane.active .standnummer')
    size = angular.element('#nav-tabBanner_a .tab-pane.active .size')
    
    imgElement = angular.element('#nav-tabBanner_a .tab-pane.active .banner_image')
    
    hall = hallSelect.val()
    booth = standInput.val()
    bannerSize = size.val()
        
    # Validate inputs
    if !hall || !booth
      return
      
    # Build the config for banner.php
    config = {
      hall: hall,
      booth: booth,
      size: bannerSize
    }
  
    # Send request to banner.php
    $http.post('core/banner.php', JSON.stringify(config))
      .then (response) ->
        if response.data.success
          imgElement.prop('src', response.data.base64)
        else
          alert('Fehler beim Generieren des Banners.')
      .catch (error) ->
        console.error('Error generating banner:', error)
        alert('Fehler beim Generieren des Banners.')
  